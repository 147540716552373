<template>
  <div class="info-container">
    <!-- <NavBack :navBackMsg="navBackMsg"></NavBack> -->
    <!-- 覆盖最上方静止选择和修改数据 -->
    <div class="disabled-box" v-if="!saveShow"></div>
    <div class="info-cen">
      <div class="title">
        <div class="title-l">
          <svg class="closeImg" aria-hidden="true">
            <use :xlink:href="'#icon-gongzuojingli'" />
          </svg>
          {{ navBackMsg.title }}
        </div>
        <div class="title-r" v-if="menuLayout !== '1'">
          <van-button icon="add-o" round type="info" @click="addItem"
            >添加</van-button
          >
        </div>
      </div>
      <div
        class="info-main"
        v-for="(parent, index) in fromData"
        :key="index"
        style="margin-bottom: 15px"
      >
        <van-cell-group>
          <van-cell v-for="(item, index) in parent" :key="index">
            <van-field
              v-if="item.type === 'text'"
              v-model="item.value"
              :label="item.label"
              :required="item.required"
              right-icon="custom"
              placeholder="请填写"
              input-align="right"
              label-width="7.0em"
              :maxlength="item.propertyLength"
            >
              <van-icon
                class="iconfont icon-kong"
                class-prefix="icon"
                slot="right-icon"
                name="custom"
              ></van-icon>
            </van-field>
            <van-field
              v-if="item.type === 'number'"
              v-model="item.value"
              :label="item.label"
              :required="item.required"
              type="number"
              right-icon="custom"
              placeholder="请填写"
              input-align="right"
              :maxlength="item.propertyLength"
            >
              <van-icon
                class="iconfont icon-kong"
                class-prefix="icon"
                slot="right-icon"
                name="custom"
              ></van-icon>
            </van-field>
            <van-field
              v-if="item.type === 'textArea'"
              v-model="item.value"
              :label="item.label"
              rows="1"
              type="textarea"
              :required="item.required"
              :autosize="item.autosize"
              right-icon="custom"
              placeholder="请填写"
              input-align="right"
              :maxlength="item.propertyLength"
            >
              <van-icon
                class="iconfont icon-kong"
                class-prefix="icon"
                slot="right-icon"
                name="custom"
              ></van-icon>
            </van-field>
            <SelectView
              v-if="item.type === 'select'"
              :value.sync="item.value"
              :list="item.list"
              :label="item.label"
              :required="item.required"
            ></SelectView>
            <DatetimeView
              v-if="item.type === 'date'"
              :value.sync="item.value"
              :type="item.type"
              :label="item.label"
              :required="item.required"
            ></DatetimeView>
            <UploadImgView
              v-if="item.type === 'uploadImg'"
              :label="item.label"
              :value.sync="item.value"
              :importType="item.fieldCode"
              :disabled="!saveShow"
            >
            </UploadImgView>
            <SwitchView
              v-if="item.type === 'boolean'"
              :value.sync="item.value"
              :label="item.label"
              :required="item.required"
              @switchValChange="switchValChange(item)"
            ></SwitchView>
          </van-cell>
          <van-cell v-if="index !== 0">
            <p class="del-btn" @click="delItem(index)">删除</p>
          </van-cell>
        </van-cell-group>
      </div>
    </div>
    <div class="footer">
      <van-button type="info" @click="save" v-btnClick v-if="saveShow"
        >保存</van-button
      >
    </div>
  </div>
</template>
<script>
import SelectView from "../../components/select-view/index"; //下拉选择
import DatetimeView from "../../components/Datetime-view/index"; //日期类型选择
import UploadImgView from "../../components/upload-img-view/index"; //上传证书
import NavBack from "../../components/nav-back/index";
import SwitchView from "../../components/switch-view/index"; //滑块
import { Toast } from "vant";
import { mapGetters } from "vuex";
import API from "../../api/http";
import { Dialog } from "vant";
/**
  type 类型：
     input：普通input框,右边无icon
    input2: 右边icon有点击事件的input框
    select：下拉
    date：日期
    area：省市区
    textarea：多列展示
    identity：身份证
 */
export default {
  components: {
    SelectView,
    DatetimeView,
    UploadImgView,
    NavBack,
    SwitchView,
  },
  data() {
    return {
      navBackMsg: {
        title: "工作经历", // 导航文字内容
        backBtn: true,
      },
      fromData: [],
      cardCode: false,
      menuLayout: "1",
      approvalStatus: "",
    };
  },
  computed: {
    ...mapGetters("user", {
      clientCode: "clientCode",
      temporaryCode: "temporaryCode",
      mcode: "mcode",
    }),
    saveShow() {
      if (this.mcode) {
        //人员状态未10、20,可以再次保存
        if (this.approvalStatus === 10 || this.approvalStatus === 20) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
  },
  created() {
    this.templateMenuId = this.$route.query.id;
    this.navBackMsg.title = this.$route.query.title;
    this.menuLayout = this.$route.query.menuLayout;
    this.approvalStatus = Number(this.$route.query.astatus);
    this.getInit();
  },
  mounted() {},

  methods: {
    getInit() {
      //初始化数据
      API.getFieldByMenu({
        templateMenuId: this.templateMenuId,
        clientCode: this.clientCode,
        temporaryCode: this.temporaryCode,
        mcode: this.mcode,
      }).then((res) => {
        let result = res.data;
        this.fromData = this.setList(result);
      });
    },
    addItem() {
      //t添加
      if (
        this.menuLayout !== "N" &&
        Number(this.menuLayout) === this.list.length
      ) {
        Toast({
          message: `最多添加${this.menuLayout}个${this.navBackMsg.title}`,
          className: "totast-cum",
        });
        return;
      }
      let arr = JSON.parse(JSON.stringify(this.fromData[0]));
      arr.forEach((element) => {
        element.value = "";
        if (element.type === "boolean") {
          element.value = false;
        }
      });
      this.fromData.push(arr);
    },
    findCheckedNum() {
      let num = 0;
      this.fromData.forEach((parentItem) => {
        parentItem.forEach((childItem) => {
          if (
            childItem.value &&
            childItem.fieldCode === "latest_working_experience"
          ) {
            num++;
          }
        });
      });
      return num;
    },
    switchValChange(item) {
      //只能设置一个紧急联系人
      if (item.value) {
        if (this.findCheckedNum() > 1) {
          Toast({
            message: "最近经历只能设置一个",
          });
          setTimeout(() => {
            this.$set(item, "value", false);
          }, 200);
        }
      }
    },
    setList(arrList) {
        let newArr = [];
        arrList.forEach((arr) => {
            let menuRelList1 = arr.filter(
                (item) => item.propertyType === "uploadImg"
            );
            let menuRelList2 = arr.filter(
                (item) => item.propertyType !== "uploadImg"
            );

            let arr1 = menuRelList1.map((child) => {
                return {
                    label: child.fieldDesc,
                    value: child.fieldValue,
                    fieldCode: child.fieldCode,
                    type: child.propertyType,
                    tipsDesc: child.tipsDesc,
                    required: child.isNotNull === 1 ? true : false,
                    clientTemplateCheckField: child.clientTemplateCheckField,
                    // list: child.defaultValue === '' ? [] : [{url:child.defaultValue}]
                };
            });

            let data = menuRelList2.map((child) => {
                let value = child.fieldValue;
                if (child.propertyType === "boolean") {
                    value = JSON.parse(value || "false");
                }
                return {
                    label: child.fieldDesc,
                    value: value,
                    type: child.propertyType,
                    fieldCode: child.fieldCode,
                    tipsDesc: child.tipsDesc,
                    required: child.isNotNull === 1 ? true : false,
                    propertyLength:
                    child.propertyLength &&
                    child.propertyLength !== 0 &&
                    child.propertyLength !== ""
                        ? child.propertyLength
                        : 200,
                    clientTemplateCheckField: child.clientTemplateCheckField,
                    list: child.downList.map((dom) => {
                        return {
                            // value: dom.dataCode,
                            value:
                            child.fieldDesc === "国际区域码"
                                ? dom.dataValue
                                : dom.dataCode,
                            // text: "+" + dom.dataValue,
                            text:
                            child.fieldDesc === "国际区域码"
                                ? "+" + dom.dataValue
                                : dom.dataValue,
                        };
                    }),
                };
            });
            if (arr1.length > 0) {
                data.push(...arr1);
            }
            newArr.push(data);
        });
        return newArr;

      //数据重组
    //   let data = arr.map((item) => {
    //     item = item.map((child) => {
    //       let value = child.fieldValue;
    //       if (child.propertyType === "boolean") {
    //         value = JSON.parse(value || "false");
    //       }
    //       return {
    //         label: child.fieldDesc,
    //         value: value,
    //         type: child.propertyType,
    //         fieldCode: child.fieldCode,
    //         tipsDesc: child.tipsDesc,
    //         required: child.isNotNull === 1 ? true : false,
    //         propertyLength:
    //           child.propertyLength &&
    //           child.propertyLength !== 0 &&
    //           child.propertyLength !== ""
    //             ? child.propertyLength
    //             : 200,
    //         clientTemplateCheckField: child.clientTemplateCheckField,
    //         list: child.downList.map((dom) => {
    //           return {
    //             // value: dom.dataCode,
    //             value:
    //               child.fieldDesc === "国际区域码"
    //                 ? dom.dataValue
    //                 : dom.dataCode,
    //             // text: "+" + dom.dataValue,
    //             text:
    //               child.fieldDesc === "国际区域码"
    //                 ? "+" + dom.dataValue
    //                 : dom.dataValue,
    //           };
    //         }),
    //       };
    //     });

    //     return item;
    //   });

    //   return data;
    },
    isMmergencycontact() {
      //是否存在 最新经历字段
      let flag = false;
      let list = this.fromData[0];
      for (const c of list) {
        if (c.fieldCode === "latest_working_experience") {
          flag = true;
        }
      }
      return flag;
    },
    save() {
      //保存
      let flag = true;
      for (let item of this.fromData) {
        if (!this.setSaveList(item)) {
          flag = false;
          break;
        }
      }
      if (flag && this.isMmergencycontact()) {
        if (this.findCheckedNum() === 0) {
          flag = false;
          Toast({
            message: "请选择一个最近经历",
            className: "totast-cum",
          });
        }
      }
      if (flag) {
        //成功
        let list = this.setFroms();
        API.addBasicFieldVale({
          templateMenuId: this.templateMenuId,
          temporaryCode: this.temporaryCode,
          clientCode: this.clientCode,
          menuFieldRelList: list,
          mcode: this.mcode,
        }).then((res) => {
          Toast({
            message: res.message,
            className: "toast-yellow",
          });
          this.saveBack();
        });
      }
    },
    saveBack() {
      setTimeout(() => {
        this.$router.back(-1);
        // this.$router.push({ path: "/user", query: { ...this.$route.query } });
      }, 1000);
    },
    setFroms() {
      let menuFieldRelList = this.fromData.map((item) => {
        item = item.map((element) => {
          return {
            fieldCode: element.fieldCode,
            fieldValue: element.value,
          };
        });
        return item;
      });

      return menuFieldRelList;
    },
    setSaveList(arr) {
      let flag = true;
      for (let item of arr) {
        if (item.type !== "boolean") {
          if (item.required && !item.value.trim()) {
            let msg = item.label + "不能为空";
            if (item.tipsDesc) {
              msg = item.tipsDesc + item.label;
            }
            Toast({
              message: msg,
              className: "totast-cum",
            });
            flag = false;
            break;
          } else if (
            item.value !== "" &&
            item.clientTemplateCheckField !== null &&
            item.clientTemplateCheckField.checkFieldType === "dateCheck"
          ) {
            //正则匹配结束日期不能早于开始日期
            let reg = item.clientTemplateCheckField.checkFieldRegexp;
            let obj = arr.filter((item) => item.fieldCode === reg) || [];
            if (obj.length !== 0) {
              let endTimer = Date.parse(new Date(item.value));
              let strTimer = Date.parse(new Date(obj[0].value));
              if (endTimer <= strTimer) {
                Toast({
                  message: `${item.label}不能晚于${obj[0].label}`,
                  className: "totast-cum",
                });
                flag = false;
                break;
              }
            }
          }
        }
      }
      return flag;
    },
    delItem(index) {
      //删除某一项
      Dialog.confirm({
        // title: '标题',
        message: "确认要删除吗？",
      })
        .then(() => {
          // on confirm
          this.fromData.splice(index, 1);
        })
        .catch(() => {
          // on cancel
        });
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/common.scss";
</style>